import React from 'react'

const ServiceSection = (props) => {
	return (
		<section id="services" className="fp-services-wrapper">
			<div className="w3-padding-32 fp-margin">
				<h3 className="w3-center">Services</h3>
				<div className="fp-services-container">
					<div className="fp-service-item">
						<button className="fp-service-badge">
							<i className="flaticon flaticon-wrench-1"></i>
						</button>
						<h4>Plumbing</h4>
						<p>
							General plumbing installation and repairs, piping for new construction and home renovations, appliance installation, and more.  Emergency plumbing services including severe leaks, flooding, and more.
						</p>
					</div>
					<div className="fp-service-item">
						<button className="fp-service-badge">
							<i className="flaticon flaticon-shower"></i>
						</button>
						<h4>Water Heaters</h4>
						<p>
							Installation, repair, and replacement of water heaters, tankless water heaters, hot water solar systems, hydronic boilers, steam boilers, and more.
						</p>
					</div>
					<div className="fp-service-item">
						<button className="fp-service-badge">
							<i className="flaticon flaticon-extractor"></i>
						</button>
						<h4>Sump/Backup Pumps</h4>
						<p>
							Installation, repair, and replacement of sump pumps, backup pumps, well pumps, and more.
						</p>
					</div>
					<div className="fp-service-item">
						<button className="fp-service-badge">
							<i className="flaticon flaticon-plunger"></i>
						</button>
						<h4>Drain Cleaning</h4>
						<p>
							Drain cleaning, clogged or slow drain repair, video pipe inspection, and more.
						</p>
					</div>
					<div className="fp-service-item">
						<button className="fp-service-badge">
							<i className="flaticon flaticon-water-heater-1"></i>
						</button>
						<h4>Gas Work</h4>
						<p>
							Gas line installation, replacement and repairs of existing gas lines, and more.
						</p>
					</div>
					<div className="fp-service-item">
						<button className="fp-service-badge">
							<i className="flaticon flaticon-faucet"></i>
						</button>
						<h4>Water/Sewer Service</h4>
						<p>
							Main water service and main sewer replacement and repairs.
						</p>
					</div>
					<div className="fp-service-item">
						<button className="fp-service-badge">
							<i className="flaticon flaticon-radiator"></i>
						</button>
						<h4>Heating</h4>
						<p>
							Radiant heating, heating controls, and more.
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ServiceSection;
