
export const WELCOME_ANCHOR = '#welcome';
export const SERVICES_ANCHOR = '#services';
export const CONTACT_ANCHOR = '#contact';
export const TESTIMONIALS_ANCHOR = '#testimonials';
export const PROMOTIONS_ANCHOR = '#promotions';

export const scrollToAnchor = (anchorSelector) => {
	const element = document.querySelector(anchorSelector);
	const y = element.offsetTop - 88;
	window.scrollTo(0, y);
};
