import React from 'react'
import {
	scrollToAnchor,
	WELCOME_ANCHOR,
	SERVICES_ANCHOR,
	CONTACT_ANCHOR,
	TESTIMONIALS_ANCHOR,
	PROMOTIONS_ANCHOR
} from '../utils/nav-utils';
import logo from '../images/fluid-logo.png';
import { FaPhone, FaEnvelopeOpen } from 'react-icons/fa';

const Header = (props) => {

	const emailLink = `mailto:${props.email}`;
	const phoneLink = `tel:+1-${props.phone}`;

	return (
		<div className="w3-top fp-opacity">
			<div className="w3-black">
				<div className="fp-header-contact">
					<a className="fp-contact-link w3-small"
					   href={phoneLink}>
						<FaPhone />
						{props.phone}
					</a>
					<a className="fp-contact-link w3-small"
					   href={emailLink}>
						<FaEnvelopeOpen />
						{props.email}
					</a>
					<br className="w3-clear" />
				</div>
			</div>
			<div className="w3-white w3-border-light-grey w3-border-bottom w3-border-top">
				<div className="w3-bar w3-left-align fp-margin">
					<a onClick={() => scrollToAnchor(WELCOME_ANCHOR)}
					   className="fp-logo-bar-item w3-button w3-hover-none">
						<img className="fp-logo" src={logo} alt="fluid plumbing and heating logo" />
					</a>
					<a onClick={() => scrollToAnchor(SERVICES_ANCHOR)}
					   className="fp-bar-item w3-button w3-hide-small">
						Services
					</a>
					<a onClick={() => scrollToAnchor(CONTACT_ANCHOR)}
					   className="fp-bar-item w3-button w3-hide-small">
						Contact
					</a>
					<a onClick={() => scrollToAnchor(TESTIMONIALS_ANCHOR)}
					   className="fp-bar-item w3-button w3-hide-small">
						Testimonials
					</a>
					<a onClick={() => scrollToAnchor(PROMOTIONS_ANCHOR)}
					   className="fp-bar-item w3-button w3-hide-small">
						Promotions
					</a>
				</div>

				{/*<div id="navMobile" className="w3-bar-block w3-black w3-hide w3-hide-large w3-hide-medium">*/}
					{/*<a href="#services" className="w3-bar-item w3-button">Services</a>*/}
					{/*<a href="#contact" className="w3-bar-item w3-button">Contact</a>*/}
					{/*<a href="#testimonials" className="w3-bar-item w3-button">Testimonials</a>*/}
					{/*<a href="#promotions" className="w3-bar-item w3-button">Promotions</a>*/}
				{/*</div>*/}
			</div>
		</div>

	);
};

export default Header
