import React from 'react'
import {FaEnvelopeOpen, FaLocationArrow, FaPhone} from "react-icons/fa";


const PHONE_CONTACT = 'phone';
const EMAIL_CONTACT = 'email';
const TEXT_CONTACT = 'text';

const SEND_MAIL_URL = 'https://www.fluidplumbingheating.com/send-mail.php';

export class ContactSection extends React.Component {

	constructor(props){
		super(props);

		const {email, phone, address} = props;

		this.state = {
			isSubmitting: false,
			isSubmitted: false,
			contactName: '',
			contactEmail: '',
			contactPhone: '',
			contactDescription: '',
			contactMethod: EMAIL_CONTACT,
			email,
			phone,
			address
		};

		// bind event listeners to class context
		this.onContactNameChange = this.onContactNameChange.bind(this);
		this.onContactPhoneChange = this.onContactPhoneChange.bind(this);
		this.onContactEmailChange = this.onContactEmailChange.bind(this);
		this.onContactDescriptionChange = this.onContactDescriptionChange.bind(this);
		this.onContactMethodChange = this.onContactMethodChange.bind(this);
		this.onSubmitForm = this.onSubmitForm.bind(this);
	}

	onContactNameChange(e){
		this.setState({
			contactName: e.currentTarget.value
		});
	}

	onContactEmailChange(e){
		this.setState({
			contactEmail: e.currentTarget.value
		});
	}

	onContactPhoneChange(e){
		this.setState({
			contactPhone: e.currentTarget.value
		});
	}

	onContactDescriptionChange(e){
		this.setState({
			contactDescription: e.currentTarget.value
		});
	}

	onContactMethodChange(e){
		this.setState({
			contactMethod: e.currentTarget.value
		});
	}

	onSubmitForm(e){
		this.setState({isSubmitting: true});

		const {
			contactName,
			contactEmail,
			contactPhone,
			contactMethod,
			contactDescription
		} = this.state;

		const data = {
			name: contactName,
			email: contactEmail,
			phone: contactPhone,
			preferredContact: contactMethod,
			description: contactDescription
		};

		fetch(SEND_MAIL_URL, {
			method: "POST",
			mode: "no-cors",
			cache: "no-cache",
			credentials: "omit",
			headers: { "Content-Type": "application/json" },
			redirect: "follow",
			referrer: "no-referrer",
			body: JSON.stringify(data),
		}).then(response => {
			console.log("Email sent successfully.");
			this.setState({isSubmitted: true, isSubmitting: false});
		}).catch(error => {
			console.error("Failed to send email: ", error);
			this.setState({isSubmitted: false, isSubmitting: false});
		});

		e.preventDefault();
	}

	renderContactForm(){
		const {
			isSubmitted,
			isSubmitting,
			contactName,
			contactMethod,
			contactPhone,
			contactEmail,
			contactDescription
		} = this.state;

		if(isSubmitted){
			return (
				<div className="w3-center">
					<h4>Thank you.</h4>
					<p>We'll get back to you as soon as possible.</p>
				</div>
			);
		}else{
			return (
				<form>
					<h4 className="w3-center">Request an Appointment</h4>

					<label>Name</label>
					<input className="fp-input" name="name"
					       value={contactName}
					       onChange={this.onContactNameChange} />

					<label>Phone Number</label>
					<input className="fp-input" name="phone" type="tel"
					       value={contactPhone}
					       onChange={this.onContactPhoneChange} />

					<label>Email</label>
					<input className="fp-input" name="email" type="email"
					       value={contactEmail}
					       onChange={this.onContactEmailChange} />

					<label>Description of Issue</label>
					<textarea className="fp-input" name="description" rows="3"
					          value={contactDescription}
					          onChange={this.onContactDescriptionChange} />

					<label>Preferred Contact Method</label><br/>
					<span className="w3-margin-right">
						<input className="w3-radio" type="radio" name="preferredContact" value="email"
						       checked={contactMethod === EMAIL_CONTACT}
						       onChange={this.onContactMethodChange} />
							<label>Email</label>
						</span>

					<span className="w3-margin-right">
						<input className="w3-radio" type="radio" name="preferredContact" value="phone"
						       checked={contactMethod === PHONE_CONTACT}
						       onChange={this.onContactMethodChange} />
							<label>Phone</label>
						</span>

					<span className="w3-margin-right">
						<input className="w3-radio" type="radio" name="preferredContact" value="text"
						       checked={contactMethod === TEXT_CONTACT}
						       onChange={this.onContactMethodChange} />
							<label>Text Message</label>
						</span>

					<div className="fp-submit">
						<button className="fp-button"
						        onClick={this.onSubmitForm}
						        disabled={isSubmitting === true}>
							Submit
						</button>
					</div>
				</form>
			);
		}
	}

	render(){
		const {
			email,
			phone,
			address
		} = this.state;

		const emailLink = `mailto:${email}`;
		const phoneLink = `tel:+1-${phone}`;
		const addressLink = `${address.street}, ${address.city}, ${address.state}, ${address.zip}`;

		return (
			<section id="contact" className="fp-contact-wrapper">
				<div className="fp-contact-info">
					<h4>Contact Us</h4>
					<div className="fp-contact-detail">
						<a href={phoneLink}>
							<FaPhone className="w3-margin-right"/>
							phone: {phone}
						</a>
						<a href={emailLink}>
							<FaEnvelopeOpen className="w3-margin-right"/>
							email: {email}
						</a>
					</div>
				</div>
				<div className="fp-contact-form">
					{this.renderContactForm()}
				</div>
			</section>
		);
	}
}

