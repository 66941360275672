import React from 'react'
import { FaFacebook, FaGoogle, FaYelp } from 'react-icons/fa';

const renderServiceArea = (serviceArea, index) => (<span key={index}>&bull; {serviceArea}</span>)

const year = new Date().getFullYear();

const Footer = (props) => {
	const serviceAreas = props.serviceAreas.sort();

	return (
		<footer className="w3-white">
			<div className="fp-margin w3-padding-32 w3-center">
				<p>
					Follow us or read more reviews.
				</p>
				<div>
					<button className="fp-social-button">
						<a href="https://www.facebook.com/fluidplumb"
						   target="_blank" rel="noopener noreferrer">
							<FaFacebook className="icon" />
						</a>
					</button>
					<button className="fp-social-button">
						<a href="https://g.page/Fluid-Plumbing-Heating"
						   target="_blank" rel="noopener noreferrer">
							<FaGoogle className="icon" />
						</a>
					</button>
					<button className="fp-social-button">
						<a href="https://www.yelp.com/biz/fluid-plumbing-and-heating-nottingham"
						   target="_blank" rel="noopener noreferrer">
							<FaYelp className="icon" />
						</a>
					</button>
				</div>
				<div className="w3-padding-16">
					<h4>Service Areas</h4>
					<div className="fp-service-areas">
						{serviceAreas.map(renderServiceArea)}
						<br/>
						<h6>All Other Areas in Baltimore City, Baltimore County, Harford County</h6>
					</div>
				</div>
				<div>
					&copy; {year} Fluid Plumbing and Heating
				</div>
			</div>
		</footer>
	);
};

export default Footer
