import React from 'react'
import {CONTACT_ANCHOR, scrollToAnchor} from "../utils/nav-utils";

const HeroSection = () => {
	return (
		<section id="welcome" className="fp-hero-wrapper">
			<div className="fp-hero-content">
				<div className="w3-large">Quality craftsmanship, genuine customer service.</div>
				<div className="w3-xxlarge">Welcome to Fluid Plumbing & Heating.</div>
				<p>
					When you need help, you want a plumbing company who is highly skilled — but also really cares — to come to your door. That’s why we treat every appointment and project like we’re in our own home. So you can trust you’ll always get the best solution with great service, at a fair price.
				</p>
				<button className="fp-button"
				        onClick={() => scrollToAnchor(CONTACT_ANCHOR)}>
					Request an appointment
				</button>
			</div>
		</section>
	);
};

export default HeroSection;