import React from 'react'
import Layout from '../components/layout'
import { StaticQuery, graphql } from 'gatsby'
import HeroSection  from '../components/hero-section';
import ServiceSection from '../components/service-section';
import { ContactSection } from '../components/contact-section';
import TestimonialSection from '../components/testimonial-section';
import PromotionSection from '../components/promotion-section';

const query = graphql`
  query SiteInfoQuery {
    site {
      siteMetadata {
        email
        phone
        address {
            street
            city
            state
            zip
        }
        promotions {
            id
            discount
            description
        }
      }
    }
  }`;


const renderPage = (data) => {
	const {email, phone, address, promotions} = data.site.siteMetadata;
	return (
		<div>
			<HeroSection/>
			<ServiceSection/>
			<ContactSection email={email} phone={phone} address={address}/>
			<TestimonialSection/>
			<PromotionSection promotions={promotions}/>
		</div>
	);
};


const IndexPage = () => (
	<Layout>
		<StaticQuery
			query={query}
			render={data => renderPage(data)}
		/>
	</Layout>
);

export default IndexPage
