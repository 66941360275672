import React from 'react'
import { Link } from 'gatsby';

function renderPromotionItem(promotion) {
	const { id, discount, description } = promotion;
	return (
		<div key={id} className="fp-promotion-item">
			<span className="fp-promotion-title">{discount}</span>
			<span className="fp-promotion-description">{description}</span>
			<Link to="/print-coupon" state={{promotion}}>
				Print Coupon
			</Link>
		</div>
	);
}

const PromotionSection = (props) => {
	const { promotions } = props;

	return (
		<section id="promotions" className="fp-promotions-wrapper">
			<div className="fp-margin w3-text-white w3-center w3-padding-32">
				<h3>Promotions</h3>
				<h5>Take advantage of these limited-time-only offers.</h5>
				<div className="fp-promotions-container">
					{promotions.map(renderPromotionItem)}
				</div>
			</div>
		</section>
	);
};

export default PromotionSection;
