import React from 'react'

const TestimonialSection = (props) => {
	return (
		<section id="testimonials" className="w3-white">
			<div className="fp-margin w3-padding-32 w3-center">
				<h3>Testimonials</h3>
				<h5>Hear it from the people who matter most. Our customers.</h5>
				<div className="fp-testimonial">
					<p className="w3-medium">
						<i>"My family and I have had a good client relationship with Brian O’Connor since 2006.  He is a skilled, proficient tradesman in his field. Brian is very knowledgeable, fairly priced, and gets the job done within a reasonable timeline. He has completed various jobs in our house including, installing a new shower, glass door, toilet and plumbing, installing a new garbage disposal, installing a new water heater, as well as other various repairs.  We have always been 100% happy with his work and would recommend him to fellow families as their go to plumber.  If you want the job done right, Brian with Fluid Plumbing is your guy."</i>
					</p>
					<p>- Brenda Schruefer</p>
				</div>
				<div className="fp-testimonial">
					<p className="w3-medium">
						<i>"Brian has been available for all types of plumbing needs at our home for more than ten years. He has done emergency calls, bathroom installs, and routine maintenance. He is always professional, knowledgeable, and extremely reliable. Rarely will you find a plumber with all of these traits and a contagiously friendly personality, that is Brian."</i>
					</p>
					<p>- Joseph Willis</p>
				</div>
				<div className="fp-testimonial">
					<p className="w3-medium">
						<i>"I know its hard to find a good trust worthy plumber who is honest and can get the job done. I have been using Brian at Fluid Plumbing for over 6 years to service our home and our rental properties. Brian has always been very responsive. He will walk you through the scope of work before any job so you have a full understanding of the issue, time and cost involved. He is reliable, professional and always leaves the work area clean. If you need a plumber that you can rely on call Brian at Fluid Plumbing."</i>
					</p>
					<p>- Eric Seiler</p>
				</div>
			</div>
		</section>
	);
};

export default TestimonialSection;