import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import Footer from "./footer";
import '../styles/index.css';

const query = graphql`
  query SiteLayoutQuery {
    site {
      siteMetadata {
        title
        description
        phone
        email
        serviceAreas
      }
    }
  }`;

const Layout = ({ children }) => (
	<StaticQuery
		query={query}
		render={data => (
            <>
				<Helmet>
					<title>{data.site.siteMetadata.title}</title>
					<meta name="description" content={data.site.siteMetadata.description} />
					<meta charSet="UTF-8" />
					<meta name="viewport" content="width=device-width, initial-scale=1" />
				</Helmet>
				<Header phone={data.site.siteMetadata.phone}
				        email={data.site.siteMetadata.email}/>
				<section className="main">
					{children}
				</section>
                <Footer serviceAreas={data.site.siteMetadata.serviceAreas} />
            </>
		)}
	/>
);

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout
